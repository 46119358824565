
/* #region base styles                                   */
a { color: var(--color-a); text-decoration: none; outline:none; font-style: italic; padding: .25rem .3rem; margin: .1rem; border-radius: .7rem;}
a:hover { background-color: var(--color-a-hov); outline: 2px solid var(--color-a-foc);}
a:focus { background-color: var(--color-a-foc); color: var(--color-a1);}

code { font-family: var(--font-code); }

p { margin:0; padding:0; }
.line { width: 90%; padding: 0.25rem; }
.line p { padding: 0 0.25rem; }
/* #endregion */

/* #region app header                                    */
header { flex-shrink: 0;  /* min-width: 100vw;  */ /* SETTING HEIGHT/WIDTH CAUSES VIEWPORT BUGS WITH FLEX*/
   font-size: calc(10px + 2vmin); }
.spinner-wrapper { margin-top:.5rem; width: min(5vw,5vh); min-width: 24px; }
.spinner { width: 100%;}
/* #endregion */

/* #region app main                                      */
main {flex: 1 0 auto;}
.flexrow {
  display:inline-flex;
}
.fb {display: inline-block; margin: 0 2px; width:fit-content;
  
}
.ft {display: inline-block; margin: 0 2px;
}
.todolist {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}

.addtodo {
  background-color: #f5faff0a;
  border-radius: 2px;
  border: 2px solid #6e7073;
  padding: 2px 8px;
  color: var(--neutralPrimary);
  font-family: var(--font-sans-nuni);
  font-size: large;
}
.addtodo:hover {
  background-color: #f5faff21;
  border: 2px solid hsl(216, 2%, 64%);
}
.addtodo:focus {
  background-color: #f5faff21;
  border: 2px solid var(--themeSecondary);
  outline: 0;
}

.overlay {
  z-index: 9001;
  width:200px; height:200px;
  position:fixed;
  left:200;
  top:200;
  border-radius: 16px;
  background-color: #1d1e1f88;
  border: 4px solid hsla(210, 3%, 18%, 0.533);
  display:none;
}

/* #endregion */

/* #region app footer                                    */
footer { flex-shrink: 0;  /* height:1rem;  */ /* SETTING HEIGHT/WIDTH CAUSES VIEWPORT BUGS WITH FLEX*/
  margin-top: 0; padding: 0.75rem;
  font-family: var(--font-footer); font-size: var(--fontsize-main); }
/* #endregion */

/* #region appcurves                             */
.footer-border {
  height: var(--hf-curve-size); flex-shrink: 0;
  background-color: var(--color-shade);
}
.footer-border-inner {
  height: var(--hf-curve-size); flex-shrink: 0;
  background-color: var(--color-bg);
  border-bottom-left-radius: var(--hf-curve-radius); border-bottom-right-radius: var(--hf-curve-radius);
}
.header-border {
  height: var(--hf-curve-size); flex-shrink: 0;
  background-color: var(--color-shade);
}
.header-border-inner {
  height: var(--hf-curve-size); flex-shrink: 0;
  background-color: var(--color-bg);
  border-top-left-radius: var(--hf-curve-radius); border-top-right-radius: var(--hf-curve-radius);
}
/* #endregion */
